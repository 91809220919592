@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-image: url("https://i.ibb.co/xzYSh30/9f07310d-efcb-4ee1-8ab2-ae95076aa640.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.bg-overlay {
  background: rgba(0, 0, 0, 0.75); /* Opak siyah bir katman eklemek için */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

html,
body {
  height: 100%;
  overflow: auto;
}

body {
  @apply bg-[#121212] text-white;
}

.container {
  @apply max-w-4xl mx-auto p-4;
}

header {
  @apply flex justify-between items-center mb-8;
}

h1 {
  @apply text-3xl font-bold;
}

button {
  @apply bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded;
}

input,
select {
  @apply shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-[#b58640];
}

.question {
  @apply p-4 rounded-lg shadow-md relative;
}

.autocomplete-suggestions {
  @apply absolute bg-stone-700 border border-[#574223] rounded mt-1 w-full z-10;
}

.suggestion {
  @apply p-2 flex items-center cursor-pointer hover:!bg-stone-800;
}

select option {
  color: #333;
}

.flag {
  @apply w-6 h-4 mr-2;
}

.popup {
  @apply fixed inset-0 flex items-center justify-center z-50;
}

.popup-content {
  @apply bg-white p-4 rounded-lg shadow-lg text-center;
}

.bg-dark {
  @apply bg-[#121212];
}

.button-custom {
  background-color: rgb(181, 134, 64);
  color: white;
}

.button-custom:hover {
  background-color: rgb(181, 134, 64);
  color: white;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container {
    @apply px-2;
  }

  h1 {
    @apply text-2xl;
  }

  input,
  select,
  button {
    @apply py-2 px-3 text-sm;
  }

  .question {
    @apply p-3;
  }
}
